import React from "react";
import "./about.scss";

const About = () => {
	return (
		<div className='About'>
			<h1>About</h1>
		</div>
	);
};

export default About;
