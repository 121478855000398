import React from "react";
import "./contact.scss";

const Contact = () => {
	return (
		<div className='Contact'>
			<h1>Contact</h1>
		</div>
	);
};

export default Contact;
