import React from "react";
import "./videos.scss";

const Videos = () => {
	return (
		<div className='Videos'>
			<h1>Videos</h1>
		</div>
	);
};

export default Videos;
