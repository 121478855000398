import React from "react";
import "./header.scss";
import HeaderPic from "../../assets/images/header2.png";
// import { ReactComponent as Instagram } from "../../assets/images/iconmonstr-instagram-11.svg";
// import { ReactComponent as Youtube } from "../../assets/images/iconmonstr-youtube-6.svg";
// import { ReactComponent as TikTok } from "../../assets/images/tiktok.svg";

const Header = () => {
	return (
		<>
			<div className='header nav_gradient'>
				<img src={HeaderPic} alt='Header' />

				{/* <div className='title'>
					<h1>BESAW PRODUCTIONS</h1>
					<h4>PROFESSIONAL PHOTOGRAPHY & VIDEOGRAPHY</h4>
					<div className='social'>
						<a href='https://www.instagram.com/besaw_productions/' rel='noopener noreferrer' target='_blank'>
							<Instagram fill='white' />
						</a>
						<a href='https://www.youtube.com/channel/UCzE7Rlvp8JHC0Kf4x8UkdFg/featured' rel='noopener noreferrer' target='_blank'>
							<Youtube fill='white' />
						</a>
						<a href='https://www.tiktok.com/@besawproductions' rel='noopener noreferrer' target='_blank'>
							<TikTok fill='white' />
						</a>
					</div>
				</div> */}
			</div>
		</>
	);
};

export default Header;
