import React from "react";
import { Routes, Route } from "react-router-dom";
import { Navbar } from "./components";
import { Home, About, Galleries, Videos, Contact } from "./routes";

import "./App.scss";

const App = () => {
	return (
		<>
			<div className='App'>
				<div className='nav_bg'>
					<Navbar />
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='about' element={<About />} />
						<Route path='contact' element={<Contact />} />
						<Route path='galleries' element={<Galleries />} />
						<Route path='videos' element={<Videos />} />
					</Routes>
				</div>
			</div>
		</>
	);
};

export default App;
