import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/images/logo_white.png";
import { Link } from "react-router-dom";

import "./navbar.scss";

const Navbar = () => {
	const [toggleMenu, setToggleMenu] = useState(false);

	return (
		<>
			<div className='navbar nav_gradient'>
				<div className='navbar-links'>
					<div className='navbar-logo'>
						<Link to='/'>
							<img src={logo} alt='logo' />
						</Link>
					</div>

					<div className='navbar-links_container'>
						<ul>
							<li>
								<Link to='/'>HOME</Link>
							</li>
							<li>
								<Link to='/about'>ABOUT</Link>
							</li>
							<li>
								<Link to='/galleries'>GALLERIES</Link>
							</li>
							<li>
								<Link to='/videos'>VIDEOS</Link>
							</li>
							<li>
								<Link to='/contact'>CONTACT</Link>
							</li>
						</ul>
					</div>

					<div className='navbar-menu'>
						{toggleMenu ? (
							<RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
						) : (
							<RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
						)}
					</div>
				</div>
				{toggleMenu && (
					<div className='navbar-menu_container'>
						<div className='navbar-menu-links_container'>
							<p>
								<Link to='/' onClick={() => setToggleMenu(false)}>
									HOME
								</Link>
							</p>
							<p>
								<Link to='/about' onClick={() => setToggleMenu(false)}>
									ABOUT
								</Link>
							</p>
							<p>
								<Link to='/galleries' onClick={() => setToggleMenu(false)}>
									GALLERIES
								</Link>
							</p>
							<p>
								<Link to='/videos' onClick={() => setToggleMenu(false)}>
									VIDEOS
								</Link>
							</p>
							<p>
								<Link to='/contact' onClick={() => setToggleMenu(false)}>
									CONTACT
								</Link>
							</p>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Navbar;
