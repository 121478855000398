import React from "react";
import { Header } from "../../containers";
import "./home.scss";

const Home = () => {
	return (
		<>
			<Header />
		</>
	);
};

export default Home;
