import React from "react";
import "./galleries.scss";

const Galleries = () => {
	return (
		<div className='Galleries'>
			<h1>Galleries</h1>
		</div>
	);
};

export default Galleries;
